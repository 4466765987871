import { Link as Zelda, LinkProps } from '@creditonebank/identity-ui'
import styled from '@emotion/styled'
import { FC } from 'react'
import { Link } from 'react-router-dom'

const StyledLinkToExtend = styled(Zelda)`
  > .link-component {
    display: inherit;
    text-decoration: none;
    outline: none;
  }
`

const StyledLink = StyledLinkToExtend.withComponent('div') // Prevents the browser from crashing.

export const NavLink: FC<LinkProps> = ({ text, href }) => {
  return <StyledLink><Link className="link-component" to={href ?? '/'}>{text}</Link></StyledLink>
}
