import { Link } from 'react-router-dom'

/**
 * Is a test page for testing features or apis
 */
const TestPage = () => {
  /**
   * TEST FUNCTION
   */
  const handleOnClick = async (): Promise<void> => {
    // TODO document why this async arrow function is empty

  }

  return (
    <div>
      <h1>
        <button onClick={() => { handleOnClick() }}>Click Here</button>
        <span>I&apos;m a test page</span>
        <Link to="/404">404 Page</Link>
      </h1>
    </div>
  )
}

export default TestPage
